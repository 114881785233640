<template>
    <div class="email__row">
        <div class="email-varify__value">
            <div>{{ email }}</div>
        </div>
        <div class="email-verification__btn">
            <custom-button
                :loading="email_validation_loading"
                default
                class="btn-base btn-main"
                @on-btn-click="verificationStart"
            >
                Verify
            </custom-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Messages from '@/helpers/InterfaceMessages_en'
import { eventBus } from '@/helpers/event-bus/'

import Api from '@/helpers/api/index.js'

// VUEX
import { mapEmailFields } from '@/store/modules/email'

export default {
    name: 'EmailField',
    data() {
        return {
            email_validation_loading: false
        }
    },
    computed: {
        ...mapState('email', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapEmailFields([
            'form_data.email'
        ])
    },
    methods: {
        async verificationStart() {
            this.email_validation_loading = true
            Api.post('/api/email-verification/send-pin', {
                email: this.email
            }).then(() => {
                this.$store.commit('email/START')
                eventBus.$emit('showSnackBar', Messages.modal_sent_email_verification_pin.body, 'success');
            }).catch((error) => {
                eventBus.$emit('showSnackBar', error, 'error');
            }).finally(() => {
                this.email_validation_loading = false
            })
        }
    }
}
</script>
