<template>
    <div class="email__row no-wrap">
        <form-input
            v-model="$v.pin.$model"
            legend="PIN"
            class="form-input__pin"
            type="text"
            name="pin"
            :field.sync="pin"
            :errors="validationMessage($v.pin)"
            :is-valid="$v.pin.$dirty && !$v.pin.$anyError"
            @input.native="$v.pin.$touch()"
            @blur.native="$v.pin.$touch()"
        />

        <button
            class="btn-base btn-main email__row-submit-btn"
            @click="prevalidate"
        >
            <template v-if="email_validation_loading">
                <button-loader />
            </template>
            <span v-else>Submit</span>
        </button>
        <button
            class="btn-base btn-border email__row-reset-btn"
            @click="reset"
        >
            Reset
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { eventBus } from '@/helpers/event-bus/'

import Api from '@/helpers/api/index.js'

import ButtonLoader from '@/components/common/ButtonLoader.vue';

// validation
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationRules } from '@/validation/email/pin/RulesPin.js'
import { formMessages } from '@/validation/email/pin/MessagesPin.js'

import { mapEmailFields } from '@/store/modules/email'

export default {
    name: 'EmailPin',
    components: {
        ButtonLoader
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            pin: '',
            email_validation_loading: false
        }
    },
    computed: {
        ...mapState('email', {
            started: 'started',
            verified: 'verified',
            form_data: 'form_data'
        }),
        ...mapEmailFields([
            'form_data.email'
        ])
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        prevalidate() {
            this.validate()
        },
        submit() {
            this.email_validation_loading = true
            Api
                .post('/api/email-verification/check-pin', {
                    email: this.email,
                    pin: this.pin
                })
                .then(() => {
                    this.$store.commit('email/SUCCESS')
                    eventBus.$emit('showSnackBar', 'Email has been validated successfully!', 'success')
                    this.$emit('validated')
                })
                .catch(async () => {
                    eventBus.$emit('showSnackBar', 'Code is invalid', 'error');
                    this.pin = ''
                    this.$v.pin.$touch()
                })
                .finally(() => {
                    this.email_validation_loading = false
                })
        },
        reset() {
            this.pin = ''
            this.$store.commit('email/RESET_VERIFICATION')
        }
    }
}
</script>
<style scoped lang="scss">
    .email__row.no-wrap {
        flex-wrap: nowrap !important;
    }
</style>
