<template>
    <div class="email_wrapper">
        <email-valid
            v-if="verified"
            :email="email"
        />
        <div v-else>
            <email-field
                v-if="!started"
                ref="email-field"
            />
            <email-pin v-else />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapEmailFields } from '@/store/modules/email'
import EmailValid from './components/EmailValid'
import EmailField from './components/EmailField'
import EmailPin from './components/EmailPin'

// VUEX

export default {
    name: 'Email',
    components: {
        EmailValid,
        EmailField,
        EmailPin
    },
    computed: {
        ...mapState('email', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapGetters('client', [
            'getterMainClientInfo'
        ]),
        ...mapEmailFields([
            'form_data.email'
        ])
    },
    created() {
        this.$store.commit('email/SET_DEFAULT')
        if (this.getterMainClientInfo.email) {
            this.email = this.getterMainClientInfo.email
        }
    }
}

</script>

<style lang="scss">
.email__row {
    align-items: flex-start;
    display: flex;
    .form-select {
        width: 120px;
        min-width: 120px;
        max-width: 120px;
        margin-right: -1px !important;
        border: none !important;
        &__list {
            width: 285px;
        }
    }
    .form-input {
        &__input {
            border-radius: 0 $input-border-radius $input-border-radius 0;
        }
        &__pin {
            .form-input__input {
                font-size: 14px;
                margin: 0;
                border-radius: $input-border-radius;
            }
        }
    }
    &-verify.btn-base_disabled {
        color: rgba(0, 0, 0, 0.26) !important;
    }
    &-verify {
        .loader {
            color: $white;
        }
        &.disabled {
            border: 1px solid rgba(0, 0, 0, 0.12) !important;
            background-color: rgba(0, 0, 0, 0.12);
            color: #1e2838 !important;
            pointer-events: none;
        }
        &:hover {
            background-color: #fef3ed;
            cursor: pointer;
        }
    }
    &-pincode {
        label {
            font-size: 14px !important;
        }
    }
    &-pin {
        height: 50px !important;
        border: none;
        margin: 0 10px;
        color: $white !important;
        font-family: $font-family-base, sans-serif;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        background-image: $main-gradient;
        border-radius: $input-border-radius;
        width: auto;
        box-shadow: none;
    }
    &-submit-btn {
        max-width: calc(25% - 5px);
        margin-left: 5px;
        box-shadow: none;
    }
    &-reset-btn {
        max-width: calc(25% - 5px);
        margin-left: 5px;
        min-width: 92px;
        border-radius: $btn-base__border-radius;
        background: $white;
        font-size: 0.875rem;
        height: 48px;
        // border: 1px solid #d3d9e2;
        // color: rgba(0, 0, 0, 0.87);
        // &:hover {
        //     cursor: pointer;
        //     background: rgba(0, 0, 0, 0.12);
        // }
    }
}
.email-success-verification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px 10px 20px;
    background: #e7ffe4;
    color: $font-color-main;
    font-size: 16px;
    font-weight: 400;
    border-radius: $input-border-radius;
    position: relative;
    overflow: hidden;
    margin: 0 0 30px 0;
    &__num {
        display: flex;
        align-items: center;
    }
    &__icon {
        width: 30px;
        height: 30px;
        background: #59c74d;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        svg {
            color: #fff;
        }
    }
    &:before {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #59c74d;
    }
}
.email-verification {
    position: relative;
    width: 100%;
    height: 52px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin: 5px 0 30px 0;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background-color: $second-color;
    }
    &__num {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 0 0 30px;
        background-color: #fff5f1;
        flex-grow: 2;
    }
    &__btn {
        margin-left: 10px;
        height: 100%;
        // width: 230px;
        a {
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100% !important;
            padding: 0;
            width: 160px;
        }
    }
    &__switch {
        display: flex;
        height: 100%;
        align-items: center;
        &-text {
            margin-right: 20px;
        }
    }
}
.email-varify__value {
    align-self: stretch;
    border: $input-border-success;
    border-radius: $input-border-radius;
    flex-grow: 1;
    padding: 0 10px;
    font-weight: 700;
    max-width: calc(100% - 100px);
    div {
        margin-top: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media all and (max-width: 768px) {
    .email-varify__value {
        max-width: calc(100% - 150px);
    }
}

@media all and (max-width: 650px) {
    .email-varify__value {
        max-width: 100%
    }
}

@media all and (max-width: 480px) {
    .email-varify__value {
        max-width: calc(100% - 175px)
    }
}

.email__form-col_btn {
    display: flex;
    button {
        height: 50px !important;
    }
}
.profile-info__form .flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}
.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}
.flag-icon:before {
    content: '\00a0';
}
.country-code {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 0 !important;
}

.profile-info .email__row {
    width: 50%;
    margin: 0 auto;
}
@media all and (max-width: 1200px) {
    .profile-info .email__row {
        width: 70%;
    }
}

@media all and (max-width: 576px) {
    .email__form-col_btn {
        margin: 33px 0 -5px 0;
    }
    .profile-info .email__row {
        width: 100%;
    }
}

@media all and (max-width: 480px) {
    .email-verification {
        &__num {
            padding: 0 0 0 10px;
        }
        &__btn {
            margin-left: 10px;
            a {
                width: 80px;
                padding: 0 !important;
            }
        }
    }
}
</style>
