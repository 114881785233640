<template>
    <entering-form-layout>
        <div class="authentication__form-wrapper">
            <div
                class="authentication__form fake-reviews__form"
                :style="{width: $route.name === 'fake-reviews' ? '1000px' : ''}"
            >
                <!-- signing login section -->
                <div class="signin-section form-block">
                    <div class="form-block__body-wrapper">
                        <div
                            class="form-block__body fake-reviews"
                        >
                            <div class="form-block__header">
                                <h2 class="form-block__title">
                                    Beware of fake reviews
                                </h2>
                            </div>
                            <div class="fake-reviews-container">
                                <div class="fake-reviews-section">
                                    <div class="fake-reviews-content-title">
                                        Fake reviews about our website may be published by unscrupulous competitors
                                    </div>
                                    <p class="fake-reviews-content-text">
                                        Our company has operated successfully for many years. Unfortunately, in this age, a lot of our competitors resort to pathetic business practices.
                                        They operate &laquo;fake review&raquo; websites to tarnish our reputation and steal our clients.
                                    </p>
                                    <div class="fake-reviews-italic-block">
                                        <div class="v-line" />
                                        <p class="fake-reviews-content-text italic">
                                            Please stay smart and do not fall prey to such tricks.
                                        </p>
                                    </div>
                                </div>
                                <div class="fake-reviews-section">
                                    <div class="fake-reviews-content-title">
                                        Here is what a typical &laquo;fake reviews&raquo; website looks like:
                                    </div>
                                    <div class="red-flags">
                                        <div class="red-flags-item">
                                            <div class="red-flags-item-left">
                                                <div class="red-flags-item-img-block">
                                                    <CloseCircle size="38" />
                                                </div>
                                            </div>
                                            <div class="red-flags-item-right">
                                                <p class="red-flags-item-text">
                                                    There is always a list of <b>&laquo;recommended websites&raquo;</b> in the sidebar or at the top of the page. Those belong to the company that owns the fake reviews portal.
                                                </p>
                                            </div>
                                        </div>

                                        <div class="red-flags-item">
                                            <div class="red-flags-item-left">
                                                <div class="red-flags-item-img-block">
                                                    <CloseCircle size="38" />
                                                </div>
                                            </div>
                                            <div class="red-flags-item-right">
                                                <p class="red-flags-item-text">
                                                    The "fake reviews" website is dedicated to essay writing services. This is done to intercept the people searching for other brands.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="fake-reviews-section">
                                    <div class="fake-reviews-content-title">
                                        Examples
                                    </div>
                                    <p class="fake-reviews-content-text">
                                        To legitimize their deception, these review websites
                                        often start with just a few positive comments about customessaymeister. This is designed to make potential
                                        customers believe that they have no ill intentions towards our Company. However, after the first
                                        few paragraphs, the negativity of their “observations and experiences'' grows exponentially,
                                        resulting in “I would not use this service” as a summary. The same goes for fake customer
                                        reviews. Here are some of the websites that engage in creating these bogus reviews:
                                    </p>
                                    <div class="examples-reviews">
                                        <div class="examples-reviews-header">
                                            Scamfighter
                                        </div>
                                        <div class="examples-reviews-body">
                                            <p class="examples-reviews-text">
                                                This review site is home to a lot of fake comments from alleged “customers.”
                                                Obviously, these comments are not real, and here are the reasons why:
                                            </p>
                                            <div class="examples-reviews-list">
                                                <div class="examples-reviews-list-item">
                                                    <div class="examples-reviews-img-block">
                                                        <CloseCircle size="38" />
                                                    </div>
                                                    <p class="examples-reviews-text">
                                                        Outdated reviews. The screenshots of our website are from older versions design, which are no longer relevant to what we offer today.
                                                    </p>
                                                </div>

                                                <div class="examples-reviews-list-item">
                                                    <div class="examples-reviews-img-block">
                                                        <CloseCircle size="38" />
                                                    </div>
                                                    <p class="examples-reviews-text">
                                                        Despite the seemingly more or less adequate balance between positive and negative comments and feedback (still, most usually around 3 stars or so), they still aggressively advertise their own websites in the process.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="examples-reviews">
                                        <div class="examples-reviews-header">
                                            Awriters
                                        </div>
                                        <div class="examples-reviews-body">
                                            <p class="examples-reviews-text">
                                                This company is owned by a direct competitor. This so-called “review website” uses fake reviews of customessaymeister. Simply put, they follow the “fake news” blueprint from <span class="bold">Scamfighter.</span>
                                            </p>
                                            <div class="examples-reviews-list">
                                                <div class="examples-reviews-list-item">
                                                    <div class="examples-reviews-img-block">
                                                        <CloseCircle size="38" />
                                                    </div>
                                                    <p class="examples-reviews-text">
                                                        Reviews are outdated. Screenshots are outdated. Weird language is used.
                                                    </p>
                                                </div>

                                                <div class="examples-reviews-list-item">
                                                    <div class="examples-reviews-img-block">
                                                        <CloseCircle size="38" />
                                                    </div>
                                                    <p class="examples-reviews-text">
                                                        Their reviews are arranged in a way to show only the worst comments from fabricated client profiles.
                                                    </p>
                                                </div>

                                                <div class="examples-reviews-list-item">
                                                    <div class="examples-reviews-img-block">
                                                        <CloseCircle size="38" />
                                                    </div>
                                                    <p class="examples-reviews-text">
                                                        Reviews are filled with ambiguous language. Statements like "We contacted an agent, and they didn’t say much" is vague and open to interpretation in any way possible. But even then, they will use it in the most negative way to “add some drama” and fool a potential customer.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="fake-reviews-btns">
                                <button
                                    class="btn-base btn-main button"
                                    @click.prevent="confirm()"
                                >
                                    Got it
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </entering-form-layout>
</template>

<script>
import EnteringFormLayout from '@/layouts/EnteringFormLayout';
import CloseCircle from 'mdi-vue/CloseCircle'

export default {
    name: 'FakeReviews',
    components: {
        EnteringFormLayout,
        CloseCircle
    },
    data: () => ({
    }),
    methods: {
        confirm() {
            const login_last_location = localStorage.getItem('login_last_location');
            if (login_last_location) {
                localStorage.removeItem('login_last_location')
                window.location.href = login_last_location
                return
            }
            this.$router.push({ name: 'orders' })
        }
    }
}
</script>

<style lang="scss">
.fake-reviews {
    max-height: 75vh;
    overflow: auto;
    @media only screen and (max-width : 480px) {
        max-height: initial;
        padding: 40px !important;
    }
    &__form {
        @media only screen and (max-width : 480px) {
            margin: 0;
        }
    }
    &-container {
        margin-bottom: 45px;
    }
    &__subtitle {
        font-size: 28px;
        text-align: center;
        margin-bottom: 3rem;
    }
    &-section {
        margin-bottom: 75px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-italic-block {
        display: flex;
        align-items: center;
        margin-bottom: 45px;
        position: relative;
        .v-line {
            width: 4px;
            border-radius: 6px;
            background: #2D389A;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }
    &-content {
        &-title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 12px;
        }
        &-text {
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 25px;
            &:last-child {
                padding-bottom: 0;
            }
            &.italic {
                font-style: italic;
                padding-left: 25px;
            }
        }
    }
    &-btns {
        display: flex;
        justify-content: flex-end;
    }
}
.red-flags {
    &-item {
        background-color: #F7F9FF;
        border-radius: 6px 8px 8px 6px;
        border-left: 6px solid $error-color;
        padding: 20px 10% 20px 18px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        &-img-block {
            margin-right: 20px;
            color: $error-color;
        }
        &-text {
            font-size: 14px;
            line-height: 1.4;
        }
    }
}
.examples-reviews {
    margin-bottom: 40px;
    &-header {
        width: 100%;
        background-color: $error-color;
        border-radius: 8px 8px 0 0;
        padding: 15px 40px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 700;
    }
    &-body {
        padding: 40px;
        background-color: #F7F9FF;
        border-radius: 0 0 8px 8px;
    }
    &-text {
        color: #373737;
        font-size: 16px;
        line-height: 24px;
        .bold {
            font-weight: 700;
        }
    }
    &-list {
        padding-top: 30px;
    }
    &-list-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 30px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    &-img-block {
        margin-right: 30px;
        color: $error-color;
    }
}
@media only screen and (max-width : 480px) {
    .red-flags-item {
        padding: 15px 45px 15px 15px;
        align-items: flex-start;
        &-img-block {
            margin-right: 10px;
        }
    }
    .examples {
        &-reviews {
            &-header {
                padding: 15px 20px;
            }
            &-body {
                padding: 30px 20px;
            }
            &-img-block {
                margin-right: 10px;
            }
        }
    }
}
</style>
