import {
    requiredIf
} from 'vuelidate/lib/validators';

export const validationRules = {
    // messengerModel: {
    //     required: requiredIf(function () {
    //         return this.chk_calls
    //     })
    // },
    from: {
        required: requiredIf(function () {
            return this.chk_calls
        })
    },
    to: {
        required: requiredIf(function () {
            return this.chk_calls
        })
    }
}
