import { passwordMessages } from '../password/Messages';

export const formMessages = {
    required: () => 'Required',
    minLength: ({ $params }) => `Must be at least ${$params.minLength.min} characters.`,
    maxLength: ({ $params }) => `Must be less than ${$params.maxLength.max} characters.`,
    // sameAs: () => 'Must be the same',
    ...passwordMessages
    // phoneValidated: () => 'Phone number is invalid'
};
