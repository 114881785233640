import {
    requiredIf
} from 'vuelidate/lib/validators';

export const validationRules = {
    pin: {
        required: requiredIf(function () {
            return this.started
        })
    }
}
