<template>
    <entering-form-layout>
        <div class="authentication__form-wrapper">
            <div
                class="authentication__form"
                :style="{width: $route.name === 'onboarding' ? '1000px' : ''}"
            >
                <!-- signing login section -->
                <div class="signin-section form-block">
                    <div class="form-block__body-wrapper">
                        <RegisterAdditionalInfo />
                        <div
                            class="form-block__body"
                            :class="{'form-block__body--onboarding': $route.name === 'onboarding'}"
                        >
                            <div class="form-block__header">
                                <h2 class="form-block__title">
                                    Update contact details
                                </h2>
                            </div>
                            <div class="onboarding-steps">
                                <div
                                    v-for="(step, index) in myComponents"
                                    :key="step.name"
                                >
                                    <span
                                        class="onboarding-steps__step-progress"
                                        :class="{'onboarding-steps__step-progress--finish' : index <= showIndex}"
                                    />
                                    <div
                                        class="onboarding-steps__step"
                                        :class="{'onboarding-steps__step--finish' : index <= showIndex}"
                                    >
                                        {{ index + 1 }}
                                    </div>
                                </div>
                            </div>
                            <div class="onboarding-container">
                                <transition-group
                                    name="slideInLeft"
                                    tag="div"
                                    class="component-container"
                                >
                                    <template v-for="(item, index) in myComponents">
                                        <div
                                            v-if="showIndex === index"
                                            :key="item.name"
                                            class="component"
                                        >
                                            <span class="component-title">{{ item.message }}</span>
                                            <component
                                                :is="item.component"
                                            />
                                            <template v-if="item.name === 'phone'">
                                                <form-checkbox
                                                    v-model="chk_calls"
                                                    color="orange darken-2"
                                                    :label="'Allow a Sales Representative to phone me'"
                                                />
                                                <template v-if="chk_calls">
                                                    <span class="component-title">Select time range:</span>
                                                    <range-date-picker
                                                        :label="'Time range'"
                                                        :from="from"
                                                        :to="to"
                                                        :flow-from="['time']"
                                                        type-from="time"
                                                        :flow-to="['time']"
                                                        type-to="time"
                                                        :have-max-date="false"
                                                        :format-from="null"
                                                        :format-to="null"
                                                        @onDatesSelect="updateDates"
                                                    />
                                                    <!-- radio-group -->
                                                    <span class="component-title">Preferred messenger:</span>
                                                    <div class="onboarding-checkobox-group">
                                                        <template
                                                            v-for="(messenger, i) in allowedMessengers"
                                                        >
                                                            <form-checkbox
                                                                :key="i"
                                                                :label="messenger.text"
                                                                color="orange darken-2"
                                                                :is-checked="messengers.some((item) => item === messenger.value)"
                                                                :value="messengers.some((item) => item === messenger.value)"
                                                                :name="messenger.value"
                                                                @input="selectMessenger($event, messenger.value)"
                                                            />
                                                        </template>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </template>
                                </transition-group>
                                <!-- <dots
                                    :count="myComponents.length"
                                    :active-index="showIndex"
                                /> -->
                            </div>
                            <div class="onboarding-btns">
                                <button
                                    class="btn-base btn-border button"
                                    @click.prevent="skip()"
                                >
                                    skip
                                </button>
                                <button
                                    class="btn-base btn-main button form-btn-next"
                                    :class="{'btn-disabled': (!isFormValid || loading)}"
                                    @click.prevent="next()"
                                >
                                    next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </entering-form-layout>
</template>

<script>
import EnteringFormLayout from '@/layouts/EnteringFormLayout';
import RegisterAdditionalInfo from '@/views/auth/RegisterAdditionalInfo';
import { eventBus } from '@/helpers/event-bus';
import { mapGetters, createNamespacedHelpers } from 'vuex'
import Email from '@/components/common/form-elements/email-verify/Email';
import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
// import Dots from '@/components/common/Dots.vue';
import RangeDatePicker from '@/components/common/form-elements/RangeDatePicker.vue'

// validation
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/onboarding/Rules'
import { formMessages } from '@/validation/onboarding/Messages'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'

import {
    ACTION_CLIENT_UPDATE_PHONE,
    ACTION_LOG,
    ACTION_CLIENT_UPDATE_SETTINGS
} from '@/store/modules/client/action-types';

import {
    CLEAR_EMAIL_REQUIRED
} from '@/store/modules/client/mutation-types';

const {
    mapActions: mapClientActions,
    mapMutations: mapClientMutations
} = createNamespacedHelpers('client');

export default {
    name: 'Onboarding',
    components: {
        EnteringFormLayout,
        RegisterAdditionalInfo,
        Email,
        PhoneNumber,
        // Dots,
        RangeDatePicker
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data: () => ({
        showIndex: 0,
        chk_calls: true,
        from: '',
        to: '',
        messengers: [],
        myComponents: [
            {
                component: PhoneNumber,
                name: 'phone',
                message: 'Please verify your phone'
            },
            {
                component: Email,
                name: 'email',
                message: 'Please verify your email'
            }
        ],
        loading: false
    }),
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterCallParams'
            // 'getterShowFakeReviews'
        ]),
        ...mapGetters('email', [
            'isEmailVerified'
        ]),
        ...mapGetters('phone', [
            'isPhoneVerified',
            'getPhoneObject'
        ]),
        allowedMessengers() {
            return [
                {
                    value: 'whatsapp',
                    text: 'WhatsApp'
                },
                {
                    value: 'facebook',
                    text: 'Facebook Messenger'
                },
                {
                    value: 'telegram',
                    text: 'Telegram'
                },
                {
                    value: 'viber',
                    text: 'Viber'
                }
            ]
        },
        isFormValid() {
            return !this.$v.$invalid && (this.myComponents[this.showIndex].name === 'phone' ? this.isPhoneVerified : true)
        }
    },
    watch: {
        getterIsAuth(value) {
            if (!value) {
                this.close()
            }
        },
        isEmailVerified(value) {
            if (value) {
                setTimeout(() => {
                    this[CLEAR_EMAIL_REQUIRED]()
                    this.next()
                }, 1500)
            }
        }
        // isPhoneVerified(value) {
        //     if (value) {
        //         setTimeout(async () => {
        //             await this.savePhone()
        //             this.next()
        //         }, 1500)
        //     }
        // }
    },
    created() {
        const { from, to, messengers } = this.getterCallParams
        this.from = from
        this.to = to
        this.messengers = messengers
    },
    methods: {
        ...mapClientActions({
            updatePhone: ACTION_CLIENT_UPDATE_PHONE,
            updateSettings: ACTION_CLIENT_UPDATE_SETTINGS,
            ACTION_LOG
        }),
        ...mapClientMutations({
            CLEAR_EMAIL_REQUIRED
        }),
        validationMessage: validationMessage(formMessages),
        skip() {
            this[ACTION_LOG]({
                action: 'skip',
                object: this.myComponents[this.showIndex].name
            })
            if (this.showIndex === this.myComponents.length - 1) {
                this.goToAccount()
                return
            }
            this.showIndex += 1
            // this.next()
        },
        goToAccount() {
            // if (this.getterShowFakeReviews) {
            //     this.$router.push({ name: 'fake-reviews' })
            //     return
            // }
            this.$router.push({
                name: 'orders'
            })
        },
        async next() {
            this.loading = true
            if (this.myComponents[this.showIndex].name === 'phone') {
                await this.savePhone()
            }
            if (this.showIndex === this.myComponents.length - 1) {
                this.goToAccount()
                return
            }
            this.loading = false
            this.showIndex += 1
        },
        async savePhone() {
            this.$v.$touch()
            try {
                await this.updatePhone({
                    ...this.getPhoneObject
                })
                await this.updateSettings({
                    chk_calls: Number(this.chk_calls),
                    contact: {
                        messenger: this.messengers,
                        from: this.from,
                        to: this.to
                    }
                })
                eventBus.$emit('showSnackBar', 'Your phone has been saved', 'success');
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
                console.error(err)
            }
        },
        updateDates({ from, to }) {
            this.from = from
            this.to = to
        },
        selectMessenger(e, type) {
            const index = this.messengers.findIndex((messanger) => messanger === type)
            if (index >= 0) {
                this.messengers.splice(index, 1)
                return
            }
            this.messengers.push(type)
        }
    }
}
</script>

<style lang="scss">

.my-3 {
    margin-top: 20px;
}
.btn-disabled {
    pointer-events: none;
}

.form-block__body {
    &--onboarding {
        border-radius: 0 12px 12px 0;
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    .phone_wrapper {
        .phone__row {
            flex-wrap: nowrap;
            .phone-verification__btn {
                width: inherit;
                margin-left: 5px;
            }
        }
    }
    .form-input__fieldset input {
        font-size: 17px !important;
    }
}

.register-terms-and-privacy{
    &--mobile{
        display: none;
        @media (max-width: 650px) {
            margin-top: 15px;
            display: flex;
        }
    }
    &--desctop{
        @media (max-width: 650px) {
            display: none;
        }
    }
}
.onboarding {
    &-title {
        font-size: 32px;
        line-height: 45px;
        font-weight: 700;
        color: #111720;
        margin-bottom: 10px;
    }
    &-container {
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    &-btns {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    &-checkobox {
        &-group {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
    }
    &-steps {
        display: flex;
        max-width: 350px;
        width: 100%;
        margin: 0 auto 20px;
        color: $main-color;
        >div{
            position: relative;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            &:first-child {
                flex-grow: 0;
                .onboarding-steps__step-progress {
                    display: none;
                }
            }
        }
        &__step {
            z-index: 1;
            padding: 10px;
            width: 50px;
            height: 50px;
            border: 4px solid;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ddd;
            font-size: 28px;
            font-weight: 700;
            border-radius: 50px;
            background: #fff;
            position: relative;
            &--finish {
                color: $main-color;
            }
            &-progress {
                display: block;
                position: absolute;
                height: 2px;
                background: #ddd;
                background: repeating-linear-gradient(
                    to right,
                    #ddd,
                    #ddd 10px,
                    transparent 10px,
                    transparent 20px
                );
                top: 50%;
                left: 10px;
                width: calc(100% - 60px);
                &--finish {
                    height: 2px;
                    background: repeating-linear-gradient(
                        to right,
                        $main-color,
                        $main-color 10px,
                        transparent 10px,
                        transparent 20px
                    );
                }
            }
        }
    }
}
.component-container {
    position: relative;
    min-height: 390px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media (max-width:480px) {
        min-height: 410px;
    }
    >.component {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right:0;
        display: grid;
        align-content: start;
        >.component-title {
            margin: 16px 0;
            font-weight: 500;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
.slideInLeft-enter-active,
.slideInLeft-leave-active {
    transition: all .3s;
    opacity: 1;
    transform: translateX(0);
}
.slideInLeft-enter
{
    opacity: 0;
    transform: translateX(100%);
}
.slideInLeft-leave-to
{
    opacity: 0;
    transform: translateX(-100%);
}
.form-btn-next {
    margin-left: auto;
}
</style>
